import "./MyRoomAdministrator.scss";
import EmptyRoomAdministrator from "./EmptyRoomAdministrator/EmptyRoomAdministrator";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDatasetAction} from "../../../../store/actions/datasetAction";
import DeleteModal from "../../../DeleteModal/DeleteModal";
import AddChecksModal from "../../../AddChecksModal/AddChecksModal";
import DatasetMurkup from "../../DatasetMarkup/DatasetMurkup";
import FolderModeComponent from "../../FolderModeComponent/FolderModeComponent";


function MyRoomAdministrator () {

    const dispatch = useDispatch()

    const {datasets,folderMode,currentDataset} = useSelector(store => store.dataset)
    const {showDeleteModal} = useSelector(store=>store.app)

    useEffect(()=>{
        async function getDataset(){
            await dispatch(getDatasetAction(currentDataset))
        }
        getDataset()
    },[])

    return (
        <div className="content">
            {folderMode ? <FolderModeComponent /> :
                <>
                    {datasets.length > 0 ?
                        <DatasetMurkup datasets={datasets}/> :
                        <EmptyRoomAdministrator />
                    }
                </>
            }

            {showDeleteModal && <DeleteModal />}
            <AddChecksModal />
        </div>

    )
}

export default MyRoomAdministrator;
