import "./SettingsDatasetBlock.scss"
import {useEffect, useState} from "react";
import DataseLogs from "../../DataseLogs/DataseLogs";
import SettingsRequirementsComponent from "./SettingsRequirementsComponent/SettingsRequirementsComponent";
import SettingsDescriptionComponent from "./SettingsDescriptionComponent/SettingsDescriptionComponent";
import {useDispatch, useSelector} from "react-redux";
import {showDeleteModalAction} from "../../../store/reducers/appReducer";
import {publishDatasetAction} from "../../../store/actions/datasetAction";
import {getEventsAction} from "../../../store/actions/FolderAction";
import SettingsLogComponent from "./SettingsLogComponent/SettingsLogComponent";

function SettingsDatasetBlock() {

    const [activeSection, setActiveSection] = useState("description")
    const {currentDataset,publishProgressShow,publishProgress,activatePublishInterval,publishEvent} = useSelector(store=>store.dataset)

    const dispatch = useDispatch()

    let publish_activate = currentDataset.validations.filter(val => val.done).length === currentDataset.validations.length &&
        (currentDataset.annotated === currentDataset.uploaded) && (currentDataset.uploaded !==0) &&
        currentDataset.uploaded >= currentDataset.info.counts
    function handleDelete(){
        dispatch(showDeleteModalAction())
    }

    function handlePublish(){
        dispatch(publishDatasetAction(currentDataset._id))
    }

    useEffect(()=>{
        if(!activatePublishInterval) return;

        const interval = setInterval(() => {
            if (!publishEvent) return;
            dispatch(getEventsAction(publishEvent,currentDataset.room_id))
        }, 2000);

        return () => clearInterval(interval);
    },[activatePublishInterval,publishEvent])

    return (
        <div className="settings_block">
            <div className="settings_header">
                <p className="gt_h4">Настройки</p>
                <button type="button" className="gt_btn_primary" disabled={!publish_activate || activatePublishInterval} onClick={handlePublish} >
                    <p className="gt_pt">Опубликовать</p>
                </button>
            </div>

            {
                publishProgressShow &&
                <div className="publish_progress">
                    <div className="validate_progress">
                        <div className="progress_block__progress-bar">
                            <div
                                className="progress_block__upload-bar"
                                style={{ width: publishProgress + '%' }}
                            />
                        </div>
                    </div>
                </div>
            }

            <div className="settings_menu">
                <button type="button" className={`settings_menu_btn ${activeSection === "description" ? "active" : ""}`}
                        style={{width: "30%"}}
                        onClick={() => setActiveSection("description")}>
                    <p className="gt_ps">Описание</p>
                </button>
                <button type="button"
                        style={{width: "40%"}}
                        className={`settings_menu_btn ${activeSection === "requirements" ? "active" : ""}`}
                        onClick={() => setActiveSection("requirements")}>
                    <p className="gt_ps">Публикация</p>
                </button>
                <button type="button" className={`settings_menu_btn ${activeSection === "logs" ? "active" : ""}`}
                        style={{width: "30%"}} onClick={() => setActiveSection("logs")}>
                    <p className="gt_ps">Логи</p>
                </button>
            </div>
            {
                activeSection === "requirements" ? <SettingsRequirementsComponent/> :
                    activeSection === "description" ? <SettingsDescriptionComponent/> :
                        activeSection === "logs" ? <SettingsLogComponent  /> :
                        ""
            }

        </div>
    )
}

export default SettingsDatasetBlock;
