import "./ModificationCircle.scss"
import {Tooltip} from "react-tooltip";

function ModificationCircle({modifiers,id = "123"}) {
    return (
        <div className="modifiers_list">
            {modifiers.slice(0, 4).map((mod, i) =>
                <div className="name_circle"
                     data-tooltip-id={`modifiers-tooltip-${id}`}
                     data-tooltip-place="top"
                     style={{left: "-" + 4 * Number(i) + "px"}}
                    >
                    <p className="gt_ps">{mod}</p>
                </div>
            )}
            {
                modifiers.length === 4 ?
                    <div className="name_circle"
                         style={{left: "-" + 4 * 4 + "px"}}
                         data-tooltip-id={`modifiers-tooltip-${id}`}
                         data-tooltip-place="top">
                        <p className="gt_ps">{modifiers[4]}</p>
                    </div> :
                    modifiers.length > 4 ?
                        <div className="name_circle"
                             style={{left: "-" + 4 * 4 + "px"}}
                             data-tooltip-id={`modifiers-tooltip-${id}`}
                             data-tooltip-place="top">
                            <p className="gt_ps">{modifiers.length - 4}+</p>
                        </div> : ""
            }
            {modifiers.length > 1 ?
                <Tooltip id={`modifiers-tooltip-${id}`} className="gt_tooltip" border="1px solid #D2DFFB">
                    <div className="modifiers_tooltips">
                        {modifiers.map(mod =>
                            <div className="name_circle"
                                 data-tooltip-id={`modifiers-tooltip-${id}`}
                                 data-tooltip-place="top">
                                <p className="gt_ps">{mod}</p>
                            </div>
                        )}
                    </div>
                </Tooltip>
                : ""
            }
        </div>
    )
}

export default ModificationCircle;
