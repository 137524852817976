import "./SettingsDescriptionComponent.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import JpgIcon from "../../../../assets/icons/jpg_icon.svg";
import DocIcon from "../../../../assets/icons/doc.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import PngIcon from "../../../../assets/icons/png_icon.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import UnknownIcon from "../../../../assets/icons/unknown.svg";
import {downloadAnnotationAction, getAnnotationAction} from "../../../../store/actions/datasetAction";

function SettingsDescriptionComponent() {

    const {files,currentDataset} = useSelector(store=>store.dataset)
    const dispatch = useDispatch()

    const [showDesc,setShowDesc] = useState(true)

    function handleDownload(file){
        dispatch(downloadAnnotationAction(currentDataset._id,file))
    }

    useEffect(()=>{
        if (currentDataset){
            dispatch(getAnnotationAction(currentDataset._id))
        }
    },[currentDataset])

    return (
        <div className="settings_description">
            {
                /*
                 <div className="settings_description_mark">
                <p className="gt_pt">Размечает:</p>
                <p className="gt_pt">?</p>
                 </div>
                 */
            }

            <div className="settings_description_block">
                <div className="description_header">
                    <p className="gt_pt">Общее описание:</p>
                    {
                        /*
                        !showDesc ?
                            <button type="button" className="gt_icon_btn"
                                    onClick={()=> setShowDesc(true)}>
                                <img src={ArrowDownIcon} alt="arrowDown"/>
                            </button>
                            :
                            <button type="button" className="gt_icon_btn" onClick={()=>setShowDesc(false)}>
                                <img src={ArrowUpIcon} alt="arrowUp"/>
                            </button>

                         */
                    }
                </div>
                {
                    showDesc ?
                        <>
                            <hr width="100%" color="#E4ECFD" size="1"/>
                            <div className="description_block">
                                <div className="description">
                                    <div className="main_desc">
                                        <div className="desc_data">
                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th ">Наименование набора данных</p>
                                                <p className="gt_pt ">{currentDataset.info.name}</p>
                                            </div>
                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Владелец набора данных</p>
                                                <p className="gt_pt ">{currentDataset.info.owner}</p>
                                            </div>
                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Описание</p>
                                                <p className="gt_pt ">{currentDataset.info.description}</p>
                                            </div>
                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Применение</p>
                                                <p className="gt_pt ">{currentDataset.info.usage}</p>
                                            </div>

                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Тип данных</p>
                                                <p className="gt_pt ">{currentDataset.info.data_type === "photo" ? "Изображения" :
                                                    currentDataset.info.data_type === "text" ? "Текст" : "Времянные ряды"}</p>
                                            </div>

                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Объем</p>
                                                <p className="gt_pt ">{currentDataset.info.counts}</p>
                                            </div>

                                            {files.length > 0 ?
                                                <div className="desc_data_item">
                                                    <p className="gt_ts gt_th">Аннотация</p>
                                                    {files.map(file =>
                                                        <button type="button"
                                                                onClick={() => handleDownload(file)}
                                                                className="gt_pt annot_btn" key={file}>
                                                            <img
                                                                src={file.split(".").slice(-1)[0] === 'jpg' || file.split(".").slice(-1)[0] === "jpeg" ? JpgIcon :
                                                                    file.split(".").slice(-1)[0] === 'doc' || file.split(".").slice(-1)[0] === "docx" ? DocIcon :
                                                                        file.split(".").slice(-1)[0] === 'xls' || file.split(".").slice(-1)[0] === "xlsx" ? XlsIcon :
                                                                            file.split(".").slice(-1)[0] === 'png' ? PngIcon :
                                                                                file.split(".").slice(-1)[0] === 'pdf' ? PdfIcon : UnknownIcon}
                                                                alt="icon"/>
                                                            <p className="gt_pt">{file}</p>
                                                        </button>)}

                                                </div> : ""
                                            }

                                            {
                                                /*
                                                <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Роли</p>
                                                {currentDataset.info.roles.map(r => (
                                                    <div className="roles_list" key={r.ogrn}>
                                                        <p className="gt_pt">{r.role}</p>
                                                        <p className="gt_pt">{r.ogrn}</p>
                                                    </div>

                                                ))}
                                            </div>
                                            <div className="desc_data_item">
                                                <p className="gt_ts gt_th">Расположение</p>
                                                <p className="gt_pt ">{currentDataset._id}</p>
                                            </div>
                                                 */
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </> : ""
                }
            </div>
        </div>
    )
}

export default SettingsDescriptionComponent;
