import FoldersTable from "./FoldersTable/FoldersTable";
import "./FolderModeComponent.scss"
import "../DatasetMarkup/DatasetMurkup.scss"
import IconBack from "../../../assets/icons/icon-arrow-left.svg";
import {hideUploaderAction, setFileUrlAction, setUploadEvent} from "../../../store/reducers/folderReducer";
import {disableFolderModeAction} from "../../../store/reducers/datasetReducer";
import {getCurrentDatasetAction, getDatasetAction} from "../../../store/actions/datasetAction";
import {useDispatch, useSelector} from "react-redux";
import ViewFolderComponent from "./ViewFolderComponent/ViewFolderComponent";

function FolderModeComponent() {
    const {currentDataset} = useSelector(store=>store.dataset)
    const dispatch = useDispatch()

    function handleBack(){
        dispatch(setUploadEvent(""))
        dispatch(hideUploaderAction())
        dispatch(disableFolderModeAction())
        dispatch(setFileUrlAction([]))
        localStorage.removeItem("current_dataset")
        localStorage.removeItem("folder_mode")
        dispatch(getCurrentDatasetAction(currentDataset._id))
        dispatch(getDatasetAction(currentDataset._id))
    }

    return (

        <div className="datasets_info">
            <div className="back_upload">
                <button type="button" onClick={handleBack} className="back_btn" >
                    <img src={IconBack} alt="Logo"/>
                    <p className="gt_h4 gt_gb">Назад</p>
                </button>
            </div>
            <div className="content_block">
                <FoldersTable/>
                <ViewFolderComponent />
            </div>
        </div>
    )
}

export default FolderModeComponent;
