import "./FilterSelectComponent.scss"

function FilterSelectComponent({options,value ="",selectChange,showOpt ,status_mode = false}){

    const data_types = [
        {type: "text",value:"Текст"},
        {type: "photo",value: "Изображения"},
        {type: "time_series",value:"Времянные ряды"}]

    function handleSelect(option){
        if (status_mode){
            selectChange(option)
        }else {
            selectChange(data_types.filter(data => data.value === option)[0].type)

        }
    }
    return (
        <div style={{position:"absolute"}}>
            <div className="filter_select_list" style={status_mode ? {width:"196px"} : {}}>
                {
                    showOpt && options.map((opt, i) => (
                        <div key={opt + i} className="filter_select_row">
                            <div className={`filter_select option_item ${i===0 ? 'first_row' : i===options.length - 1 ? 'last_row': ''}`}>
                                <button type="button" className="filter_select_option" onClick={() => handleSelect(opt)}>
                                    {
                                        status_mode ?
                                            <>
                                                {
                                                    opt === "new" ?
                                                        <div className="new_dataset_btn status_btn">
                                                            <p className="gt_ps">Новый датасет</p>
                                                        </div>
                                                        : opt === "wip" ?
                                                            <div className="wip_dataset_btn status_btn">
                                                                <p className="gt_ps">В процессе</p>
                                                            </div>
                                                            : opt === "done" ?
                                                                <div className="done_dataset_btn status_btn">
                                                                    <p className="gt_ps">Завершена</p>
                                                                </div>
                                                                :  opt === "ready" ?
                                                                    <div className="ready_dataset_btn status_btn">
                                                                        <p className="gt_ps">Готов к обработке</p>
                                                                    </div>
                                                                    : opt === "error" ?
                                                                        <div className="error_dataset_btn status_btn">
                                                                            <p className="gt_ps">Ошибка</p>
                                                                        </div> : ""
                                                }
                                            </>
                                            :
                                            <p className="gt_pt">{opt}</p>
                                    }

                                </button>
                            </div>
                            <div className="filter_hr">
                                {i === options.length - 1 ? "" : <hr width="85%" color="#E1E1E1" size="1"/>}
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FilterSelectComponent;
