import "./DatasetsTable.scss"

import SearchIcon from "../../../assets/icons/d08.svg";
import FilterIcon from "../../../assets/icons/m05.svg";
import WorkIcon from "../../../assets/icons/a05.svg";
import SettingsIcon from "../../../assets/icons/m01.svg";
import DeleteIcon from "../../../assets/icons/Delete.svg";
import LineIcon from "../../../assets/icons/Line403.svg";
import CanselIcon from "../../../assets/icons/b02.svg";
import FilterActiveIcon from "../../../assets/icons/m051.svg";
import CanselFilterIcon from "../../../assets/icons/b33.svg";

import {setFileUrlAction} from "../../../store/reducers/folderReducer";
import {
    disablePublishIntervalAction,
    disableValidIntervalAction,
    enableFolderModeAction, hidePublishProgressAction, hideValidationProgressAction,
    setCurrentDatasetAction, setPublishEventAction,
    setValidationEventAction
} from "../../../store/reducers/datasetReducer";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import PaginationComponent from "../../PaginationComponent/PaginationComponent";
import {useEffect, useState} from "react";
import {getCurrentDatasetAction} from "../../../store/actions/datasetAction";
import {showDeleteModalAction} from "../../../store/reducers/appReducer";
import StatsProgressComponent from "../SettingsDatasetBlock/StatsProgressComponent/StatsProgressComponent";
import FilterSelectComponent from "./FilterSelectComponent/FilterSelectComponent";

function DatasetsTable({datasets}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [datasetsPagination,setDatasetsPagination] = useState(datasets)
    const [currentItems,setCurrentItems] = useState(datasets)

    const {userInfo} = useSelector(store=>store.user)
    const {currentDataset} = useSelector(store=>store.dataset)

    const [activeDataset,setActiveDataset] = useState("")

    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState("")

    const [dataType, setDataType] = useState("")
    const [showTypeSelect, setShowTypeSelect] = useState(false)

    const [statusFilter, setStatusFilter] = useState("")
    const [showStatusFilter,setShowStatusFilter] = useState(false)

    const data_types = ["Текст","Изображения","Времянные ряды"]

    const status_list = ["new","wip","done","ready","error"]

    useEffect(()=>{
        setDatasetsPagination(datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(search.toLowerCase()) >= 0))
    },[datasets])

    useEffect(()=>{
        setActiveDataset(currentDataset._id)
    },[currentDataset])

    function selectDataType(value){
        setDataType(value)
        setShowTypeSelect(false)
        let new_datasets = datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            .filter(ds => ds.info.data_type === value)
        if (statusFilter){
            new_datasets = new_datasets.filter(ds => ds.status === statusFilter)
        }
        setDatasetsPagination(new_datasets)

    }

    function selectStatusFilter(value){
        setStatusFilter(value)
        setShowStatusFilter(false)

        let new_datasets = datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            .filter(ds => ds.status === value)
        if (dataType){
            new_datasets = new_datasets.filter(ds => ds.info.data_type === dataType)
        }
        setDatasetsPagination(new_datasets)

    }

    function handleInWork(dataset) {
        dispatch(setFileUrlAction([]))
        dispatch(setCurrentDatasetAction(dataset))
        dispatch(enableFolderModeAction())
        localStorage.setItem("current_dataset",JSON.stringify(dataset))
        localStorage.setItem("folder_mode","true")
        navigate("/room?status=search")
    }

    function handleActive(ds){
        dispatch(getCurrentDatasetAction(ds._id))
        setActiveDataset(ds._id)

        dispatch(setValidationEventAction(""))
        dispatch(hideValidationProgressAction())
        dispatch(disableValidIntervalAction())
        dispatch(setPublishEventAction(""))
        dispatch(hidePublishProgressAction())
        dispatch(disablePublishIntervalAction())
    }

    function handleSettings(ds){
        dispatch(getCurrentDatasetAction(ds._id))
        setActiveDataset(ds._id)
        navigate("/create_dataset?action=edit")
    }

    function handleDelete(ds){
        dispatch(getCurrentDatasetAction(ds._id))
        setActiveDataset(ds._id)
        dispatch(showDeleteModalAction())
    }

    function handleDatasetSearch(e){
        setSearch(e.target.value)
        let datasets_filter = datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
        if (dataType){
            datasets_filter = datasets_filter.filter(ds => ds.info.data_type === dataType)
        }
        setDatasetsPagination(datasets_filter)
    }

    function handleCloseSearch(){
        setShowSearch(false)
        setSearch("")
        setDatasetsPagination(datasets)
    }

    function handleResetFilters(){
        setDataType("")
        setStatusFilter("")
        setDatasetsPagination(datasets.filter(ds=> ds.info.name.toLowerCase().indexOf(search.toLowerCase()) >= 0))
    }

    return (
        <div className="datasets_table">
            <div className="datasets_title">
                <p className="gt_h4">Разметка данных</p>
                <div className="title_btns">
                    {
                        dataType || statusFilter ?
                            <button className="cansel_filter_btn" onClick={handleResetFilters}>
                                <p className="gt_pt">Сбросить фильтры</p>
                                <img src={CanselFilterIcon} alt="cansel"/>
                            </button> : ""
                    }

                    {userInfo.role === "admin" ?
                        <Link to="/create_dataset" type="button" className="gt_btn_primary gt_btn_s"><p
                            className="gt_pt">Создать набор данных</p></Link>
                        : ""}
                </div>

                </div>

            <div className="table_container">
            <table className="table_main">
                <thead>
                <tr>
                    {
                        showSearch ?
                            <th className="gt_f6 left_th search_th">
                                <div className="dataset_search">
                                    <input placeholder="Поиск" id="search" className="gt_pt" value={search} onChange={(e)=>handleDatasetSearch(e)}/>
                                    <div className="icon_block">
                                        <img src={SearchIcon} alt="search"/>
                                        <img src={LineIcon} alt="line"/>
                                        <button type="button" className="gt_icon_btn" onClick={handleCloseSearch} >
                                            <img src={CanselIcon} alt="cansel"/>
                                        </button>
                                    </div>
                                </div>
                            </th> :
                            <th className="gt_f6 left_th">
                                Набор данных
                                <button type="button" onClick={()=>setShowSearch(true)} className="gt_icon_btn">
                                    <img src={SearchIcon} alt="search"/>
                                </button>
                            </th>
                    }

                    <th className="gt_f6">Статистика</th>
                    <th className="gt_f6">
                        Тип данных
                        <button type="button" className="gt_icon_btn" onClick={()=>setShowTypeSelect(!showTypeSelect)}>
                            <img src={dataType ? FilterActiveIcon : FilterIcon} alt="filter"/>
                        </button>
                        <FilterSelectComponent options={data_types} selectChange={selectDataType} showOpt={showTypeSelect}/>
                    </th>
                    {
                        /*
                        <th className="gt_f6">Обработка</th>
                         */
                    }

                    <th className="gt_f6">
                        Статус обработки
                        <button type="button" className="gt_icon_btn" onClick={()=>setShowStatusFilter(!showStatusFilter)}>
                            <img src={statusFilter ? FilterActiveIcon : FilterIcon} alt="filter"/>
                        </button>
                        <FilterSelectComponent options={status_list} selectChange={selectStatusFilter} showOpt={showStatusFilter} status_mode={true}/>
                    </th>
                    <th className="gt_f6">Действия</th>
                </tr>
                </thead>
                {currentItems.map((dataset,i) => (
                    <button type="button"
                            key={dataset.id}
                            className={`table_item ${activeDataset === dataset._id || activeDataset === dataset.info.name ? "active" : ""}`}
                            onClick={()=>handleActive(dataset)} >
                        <tr>
                            <td className="gt_pt left_td">
                                {
                                    /*
                                    <img src={DatasetIcon} alt="dataset icon"/>
                                     */
                                }

                                <div className="name_copy_block">
                                    {dataset.info.name}
                                    {
                                        /*
                                        <button type="button" onClick={() => {
                                            navigator.clipboard.writeText(dataset._id)
                                        }} style={{height: "24px"}}>
                                            <img src={CopyIcon} alt="copyIcon"/>
                                        </button>


                                         */
                                    }
                                </div>
                                </td>
                                <td className="dataset_stats">
                                    <div className="stats_row">
                                        <p className="gt_ps">Размечено</p>
                                        <p className="gt_ps">{dataset.annotated} / {dataset.uploaded}</p>
                                    </div>
                                    <StatsProgressComponent progress={dataset.uploaded !== 0 ? dataset.annotated/dataset.uploaded * 100 : 0} end={dataset.uploaded} />
                                </td>
                                <td className="gt_pt">
                                    {
                                        dataset.info.data_type === "photo" ? "Изображения" :
                                            dataset.info.data_type === "time_series" ? "Времянные ряды" :
                                                "Текст"
                                    }
                                </td>
                                {
                                    /*
                                        <td>
                                            <ModificationCircle id={dataset._id} modifiers={dataset.modifiers}/>
                                        </td>
                                    */
                            }

                            <td>
                                {
                                    dataset.status === "new" ?
                                        <div className="new_dataset_btn status_btn">
                                            <p className="gt_ps">Новый датасет</p>
                                        </div>
                                        : dataset.status === "wip" ?
                                            <div className="wip_dataset_btn status_btn">
                                                <p className="gt_ps">В процессе</p>
                                            </div>
                                            : dataset.status === "done" ?
                                                <div className="done_dataset_btn status_btn">
                                                    <p className="gt_ps">Завершена</p>
                                                </div>
                                                : dataset.status === "ready" ?
                                                    <div className="ready_dataset_btn status_btn">
                                                        <p className="gt_ps">Готов к обработке</p>
                                                    </div>
                                                    : dataset.status === "error" ?
                                                        <div className="error_dataset_btn status_btn">
                                                            <p className="gt_ps">Ошибка</p>
                                                        </div> : ""
                                }
                            </td>
                            <td style={{gap: "8px"}}>
                                <button type="button" className="actions_btn" onClick={() => handleInWork(dataset)}>
                                    <img src={WorkIcon} alt="work"/>
                                </button>
                                <button type="button" className="actions_btn" onClick={()=>handleSettings(dataset)} disabled={userInfo.role !== "admin"}>
                                    <img src={SettingsIcon} alt="settings"/>
                                </button>
                                <button type="button" className="actions_btn" onClick={()=>handleDelete(dataset)}>
                                    <img src={DeleteIcon} alt="delete"/>
                                </button>
                            </td>
                    </tr>
                    </button>
                ))}

            </table>
            </div>
            <PaginationComponent list={datasetsPagination} setCurrentItems={setCurrentItems} />
        </div>
    )
}

export default DatasetsTable;
