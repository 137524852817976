import "./UploadProgressComponent.scss"

import CatIcon from "../../../assets/icons/pic.svg"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getDatasetLogsAction, getEventsAction} from "../../../store/actions/FolderAction";
import SettingsLogComponent from "../../MyRoom/SettingsDatasetBlock/SettingsLogComponent/SettingsLogComponent";
import PreviewModalComponent from "../PreviewModalComponent/PreviewModalComponent";
import {
    changeProcessingProgressAction,
    changeUploadProgressAction,
    hideUploaderAction
} from "../../../store/reducers/folderReducer";

function UploadProgressComponent({sanitList,files}){

    const {currentDataset} = useSelector(store=>store.dataset)

    const {uploadEvent,activateInterval} = useSelector(store=>store.folder)
    const dispatch = useDispatch()

    const [showPreview,setShowPreview] = useState(false);

    const {isVisible,uploadProgress,processingProgress, preview} = useSelector(store=>store.folder)

    useEffect(()=>{
        dispatch(hideUploaderAction())
        dispatch(changeProcessingProgressAction(0))
        dispatch(changeUploadProgressAction(0))
    },[])

    useEffect(()=>{
        if(!activateInterval) return;

        const interval = setInterval(() => {
            if (!uploadEvent) return;
            dispatch(getEventsAction(uploadEvent))
            dispatch(getDatasetLogsAction(currentDataset._id,currentDataset.room_id))
        }, 1000);

        return () => clearInterval(interval);
    },[activateInterval,uploadEvent])

    return (
        <div className="upload_progress_block">
            <div className="upload_header">
                <p className="gt_f3">Процесс загрузки</p>
                {isVisible ? <div className="upload_process">
                    <div className="title_block">
                        <p className="gt_ps">Загрузки файлов</p>
                    </div>

                    <div className="progress_block">
                        <div className="progress_block__progress-bar">
                            <div
                                className="progress_block__upload-bar"
                                style={{width: uploadProgress + '%'}}
                            />
                        </div>
                        <p className="gt_pt">{uploadProgress}%</p>
                    </div>

                    <div className="title_block">
                        <p className="gt_ps">Обработка файлов</p>
                    </div>

                    <div className="progress_block">
                        <div className="progress_block__progress-bar">
                            <div
                                className="progress_block__upload-bar"
                                style={{width: processingProgress + '%'}}
                            />
                        </div>
                        <p className="gt_pt">{processingProgress}%</p>
                    </div>
                </div> : ""}

            </div>
            <div style={{height: '100%'}}>
                {
                    isVisible ?
                        <div className="upload_logs">
                            <SettingsLogComponent search_display={false} preview={preview} showPreview={()=>setShowPreview(true)}/>
                        </div>
                        :
                        <div className="uploads_image">
                            <img src={CatIcon} alt="cat" style={{width: "200px", height: "200px"}}/>
                            <p className="gt_pt">Процесс загрузки появится после добавления файла</p>
                        </div>
                }
            </div>
            {showPreview ? <PreviewModalComponent hidePreview={()=>setShowPreview(false)}
                                                  sanitList={sanitList} files={files} />
                : ""}

        </div>
    )
}

export default UploadProgressComponent;
