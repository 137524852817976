import "./UploadAnnotation.scss";
import Attach from "../../../../assets/icons/icon_attach.svg";
import {useRef, useState} from "react";
import JpgIcon from "../../../../assets/icons/jpg_icon.svg";
import PngIcon from "../../../../assets/icons/png_icon.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import DocIcon from "../../../../assets/icons/doc.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import UnknownIcon from "../../../../assets/icons/unknown.svg";
import ZipIcon from "../../../../assets/icons/zip.svg";
import CsvIcon from "../../../../assets/icons/icon_csv.svg";
import JsonIcon from "../../../../assets/icons/icon_json.svg";
import TxtIcon from "../../../../assets/icons/icon_txt.svg";


function UploadAnnotation ({files,setFiles,setValidFiles, accept=true,accept_formats,max_count = ""}){
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = useRef(null);

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        handleChange(e)

    };

    const handleChange = function(e) {

        e.preventDefault();

        let accept = []

        if(e.type==="change" && e.target.files && e.target.files[0]){
            accept = Array.from(e.target.files)
        }
        else if (e.type==="drop" && e.dataTransfer.files && e.dataTransfer.files[0]){
            accept = Array.from(e.dataTransfer.files)
        }
        if (accept.length > 0 ){
            if (accept.filter(file => !accept_formats.includes(file.name.split(".").slice(-1)[0].toLowerCase())).length > 0){
                setValidFiles(false)
            }
            accept = accept.filter(file => accept_formats.includes(file.name.split(".").slice(-1)[0].toLowerCase()))
            let arr = [...files,...accept]

            const uniqueIds = [];

            let unique = arr.filter(element => {
                const isDuplicate = uniqueIds.includes(element.name);

                if (!isDuplicate) {
                    uniqueIds.push(element.name);

                    return true;
                }

                return false;
            });
            if(max_count){
                if (unique.length > max_count){
                    unique = unique.slice(0,max_count)
                }
            }
            setFiles(unique)
        }


    };

    const onButtonClick = (e) => {
        e.preventDefault()
        inputRef.current.click();
    };

    const handleDelete = (name) =>{
        setFiles(files.filter(file => file.name !== name))
        document.getElementById("input-file-upload").value = "";
    }

    return (
            <div className="block-file-upload " onDragEnter={handleDrag} >
                <div className="uploaded_file_list">
                    {files.map((file,index)=>(
                        <div className="uploaded_file_item" key={file.name+index}>
                            <div className="file_title">
                                <img src={file.name.split(".").slice(-1)[0].toLowerCase() === 'jpg' || file.name.split(".").slice(-1)[0].toLowerCase() === "jpeg" ? JpgIcon :
                                    file.name.split(".").slice(-1)[0].toLowerCase() === 'doc' || file.name.split(".").slice(-1)[0].toLowerCase() === "docx" ? DocIcon :
                                        file.name.split(".").slice(-1)[0].toLowerCase() === 'xls' || file.name.split(".").slice(-1)[0].toLowerCase() === "xlsx" ? XlsIcon :
                                            file.name.split(".").slice(-1)[0].toLowerCase() === 'png' ? PngIcon :
                                                file.name.split(".").slice(-1)[0].toLowerCase() === 'zip' ? ZipIcon :
                                                    file.name.split(".").slice(-1)[0].toLowerCase() === 'pdf' ? PdfIcon :
                                                        file.name.split(".").slice(-1)[0].toLowerCase() === 'json' ? JsonIcon :
                                                            file.name.split(".").slice(-1)[0].toLowerCase() === 'csv' ? CsvIcon :
                                                                file.name.split(".").slice(-1)[0].toLowerCase() === 'txt' ? TxtIcon :UnknownIcon} alt="icon"/>
                                <p className="gt_pt" style={{wordBreak:"break-all"}}>{file.name}</p>
                            </div>
                            <button type="button" className="gt_ps gt_gb" onClick={() => handleDelete(file.name)}>Удалить</button>
                        </div>
                    ))}
                </div>
                <div className="zone-file-upload" >
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                    <label id="label-file-upload" htmlFor="input-file-upload">
                        <div className="text-file-upload">
                            <div>
                                <p className="gt_ps gt_th">Перетащите файлы или выберите на компьютере.</p>
                                <p className="gt_ps gt_th">{"Допустимые расширения: "+accept_formats.toString()}</p>
                            </div>

                            <button type="button" onClick={onButtonClick} className="upload-button gt_pt"><img src={Attach} alt="attach"/><p>Выбрать файл</p></button>
                        </div>
                    </label>
                </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </div>
    )
}

export default UploadAnnotation;
