import "./Pagination.scss"
import ReactPaginate from "react-paginate";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDatasetPageAction} from "../../store/reducers/appReducer";

function PaginationComponent({list,setCurrentItems}){

    const {datasetPage} = useSelector(store => store.app)
    const {folderMode} = useSelector(store => store.dataset)

    const dispatch = useDispatch()

    const itemsPerPage = 12;
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset,setEndOffset] = useState(0)

    useEffect(()=>{
        setCurrentItems(list.slice(itemOffset, endOffset));
        setEndOffset(itemOffset + itemsPerPage)
        if(!folderMode){
            dispatch(setDatasetPageAction(itemOffset/itemsPerPage))
        }
    },[itemOffset,list,setCurrentItems,endOffset])

    const pageCount = Math.ceil(list.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % list.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                nextLabel=""
                initialPage = {!folderMode ? datasetPage : 0}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-prev"
                previousLinkClassName="page-link"
                nextClassName="page-next"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </>
    )
}

export default PaginationComponent;
