import "./FoldersTable.scss"
import "../../DatasetsTable/DatasetsTable.scss"
import {Link, useNavigate} from "react-router-dom";
import SearchIcon from "../../../../assets/icons/d08.svg";
import StatsProgressComponent from "../../SettingsDatasetBlock/StatsProgressComponent/StatsProgressComponent";
import WorkIcon from "../../../../assets/icons/a05.svg";
import LineIcon from "../../../../assets/icons/Line403.svg";
import CanselIcon from "../../../../assets/icons/b02.svg";
import CopyIcon from "../../../../assets/icons/h18.svg";
import FolderIcon from "../../../../assets/icons/folder.svg";
import FolderBigIcon from "../../../../assets/icons/big_folder.svg";


import PaginationComponent from "../../../PaginationComponent/PaginationComponent";

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ModificationCircle from "../../../ModificationCircle/ModificationCircle";
import {getEventsAction, getFilesAction, getFoldersAction} from "../../../../store/actions/FolderAction";
import {setCurrentFolderAction, setFolderFilesAction} from "../../../../store/reducers/folderReducer";

function FoldersTable() {
    const dispatch = useDispatch();
    const {uploadEvent, folders, currentFolder,activateInterval} = useSelector(store=>store.folder)
    const [currentItems,setCurrentItems] = useState(folders)
    const [activeFolder,setActiveFolder] = useState("")

    useEffect(()=>{
        setCurrentItems(folders)
    },[folders])

    useEffect(()=>{
        if (currentFolder){
            setActiveFolder(currentFolder.info.name)
        }
        async function getData(){
            if(currentFolder){
                //dispatch(setFileUrlAction([]))
                dispatch(setFolderFilesAction([]))
                await dispatch(getFilesAction(currentDataset._id,currentFolder.info.name))
            }
        }
        getData()

    },[currentFolder])


    useEffect(()=>{
        dispatch(getFoldersAction(currentDataset._id,currentFolder))
        if(!activateInterval) return;

        const interval = setInterval(() => {
            if (!uploadEvent) return;
            dispatch(getEventsAction(uploadEvent))
            dispatch(getFoldersAction(currentDataset._id,currentFolder))
        }, 2000);

        return () => clearInterval(interval);
    },[activateInterval,uploadEvent])

    const navigate = useNavigate();

    const {currentDataset} = useSelector(store=>store.dataset)

    const [activeDataset,setActiveDataset] = useState("")

    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState("")


    function handleActive(ds){
        dispatch(setCurrentFolderAction(ds))
        setActiveDataset(ds.info.name)
    }


    function handleDatasetSearch(e){
        setSearch(e.target.value)
        setCurrentItems(folders.filter(ds=> ds.info.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
    }

    function handleCloseSearch(){
        setShowSearch(false)
        setSearch("")
        setCurrentItems(folders)
    }


    function handleGoMurkup(ds) {
        dispatch(setCurrentFolderAction(ds))
        if (currentDataset.roles.includes("admin") || currentDataset.roles.includes("Разметчик")){
            if (currentDataset.info.data_type === "photo"){
                setTimeout(() => {
                    navigate("/annotation");
                }, 300);

            }
            else if (currentDataset.info.data_type === "time_series"){
                navigate("/ts_annotate");
            }
            else if (currentDataset.info.data_type === "text"){
                navigate("/text_annotate");
            }
        }
    }

    return (
        <div className="folders_table">
            <div className="datasets_table" style={{width:'100%'}}>
                <div className="datasets_title">
                    <p className="gt_h4">Набор данных </p>
                    <div className="title_btns">

                        {currentDataset.roles.includes("Поставщик") ?
                            <Link to="/upload_data" type="button" className="gt_btn_primary gt_btn_s"><p
                                className="gt_pt">Добавить файлы</p>
                            </Link>
                            : ""}
                    </div>

                </div>

                <div className="table_container">
                    <table className="table_main">
                        <thead>
                        <tr>
                            {
                                showSearch ?
                                    <th className="gt_f6 left_th search_th">
                                        <div className="dataset_search">
                                            <input placeholder="Поиск" id="search" className="gt_pt" value={search}
                                                   onChange={(e) => handleDatasetSearch(e)}/>
                                            <div className="icon_block">
                                                <img src={SearchIcon} alt="search"/>
                                                <img src={LineIcon} alt="line"/>
                                                <button type="button" className="gt_icon_btn"
                                                        onClick={handleCloseSearch}>
                                                    <img src={CanselIcon} alt="cansel"/>
                                                </button>
                                            </div>
                                        </div>
                                    </th> :
                                    <th className="gt_f6 left_th">
                                        Имя папки
                                        <button type="button" onClick={() => setShowSearch(true)}
                                                className="gt_icon_btn">
                                            <img src={SearchIcon} alt="search"/>
                                        </button>
                                    </th>
                            }

                            <th className="gt_f6">Обработка</th>
                            <th className="gt_f6">Статистика</th>
                            <th className="gt_f6">Действия</th>
                        </tr>
                        </thead>
                        {currentItems.length === 0 ?
                            <div className="empty_folders">
                                <div className="empty_folders_content">
                                    <img src={FolderBigIcon} alt="folder"/>
                                    <p className="gt_pt gt_th">Нет созданных наборов данных.
                                        <br/>
                                        Загрузите архивы с файлами для автоматического создания папки</p>
                                </div>
                            </div> : ""
                        }
                        {currentItems.map((dataset, i) => (
                            <button type="button"
                                    key={dataset.id}
                                    className={`table_item ${activeDataset === dataset._id || activeDataset === dataset.info.name ? "active" : ""}`}
                                    onClick={() => handleActive(dataset)}>
                                <tr>
                                    <td className="gt_pt left_td">

                                        <img src={FolderIcon} alt="dataset icon"/>

                                        <div className="name_copy_block">
                                            {dataset.info.name}
                                            <button type="button" onClick={() => {
                                                navigator.clipboard.writeText(dataset.info.name)
                                            }} style={{height: "24px"}}>
                                                <img src={CopyIcon} alt="copyIcon"/>
                                            </button>

                                        </div>
                                    </td>

                                    <td>
                                        <ModificationCircle id={dataset._id} modifiers={dataset.modifiers}/>
                                    </td>
                                    <td className="dataset_stats">
                                        <div className="stats_row">
                                            <p className="gt_ps">Размечено</p>
                                            <p className="gt_ps">{dataset.annotated}</p>
                                        </div>
                                        <StatsProgressComponent
                                            progress={dataset.uploaded !== 0 ? dataset.annotated / dataset.uploaded * 100 : 0}
                                            end={dataset.uploaded}/>
                                    </td>
                                    <td style={{display:"flex",justifyContent:"center"}}>
                                        <button type="button" className="actions_btn" onClick={()=>handleGoMurkup(dataset)}>
                                            <img src={WorkIcon} alt="work"/>
                                        </button>
                                    </td>
                                </tr>
                            </button>
                        ))}

                    </table>
                </div>
                <PaginationComponent list={folders} setCurrentItems={setCurrentItems}/>
            </div>
        </div>

    )
}

export default FoldersTable;
