import "./DatasetMurkup.scss"
import DatasetsTable from "../DatasetsTable/DatasetsTable";
import SettingsDatasetBlock from "../SettingsDatasetBlock/SettingsDatasetBlock";


function DatasetMurkup ({datasets}) {
    return (
        <div className="datasets_info">
            <div className="content_block">
                <DatasetsTable datasets={datasets} />
                <SettingsDatasetBlock />
            </div>
        </div>
    )
}

export default DatasetMurkup;
