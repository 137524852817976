import "./SelectGosteh.scss";
import IconArrow from "../../assets/icons/b15.svg";
import {useEffect, useState} from "react";


function SelectGosteh ({options,value ="",default_option,selectChange,index = ""}){

    const [showOpt,setShowOpt] = useState(false)
    const [selected,setSelected] = useState(value)
    useEffect(()=>{
        setSelected(value)
    },[value])
    function handleSelect(option){
        setSelected(option)
        setShowOpt(false)
        selectChange(option,index)
    }

    return (
        <div className="gt_select_block ">
            <div className={`gt_select main_select ${!showOpt ? '' : 'show_opt'}`}>
                <button type="button" className={`gt_select_option ${selected ? '' : 'gt_int'}`} onClick={()=>setShowOpt(!showOpt)}>
                    <p className="gt_pt">{selected ? selected : default_option}</p>
                    <img src={IconArrow} alt="arrow icon"/>
                </button>
            </div>
            <div className="gt_select_list">
                {
                    showOpt && options.map((opt,i)=>(
                        <div className="gt_select option_item" key={opt+i}>
                            <button type="button" className="gt_select_option" onClick={()=>handleSelect(opt)}>
                                <p className="gt_pt">{opt}</p>
                            </button>
                        </div>
                    ))
                }
            </div>

        </div>

    )
}

export default SelectGosteh;
