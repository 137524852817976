import "./DeleteChecksModal.scss"
import ImgDelete from "../../../../assets/icons/Group 277134043.svg";
import IconClose from "../../../../assets/icons/b01.svg";
import {useDispatch, useSelector} from "react-redux";
import {hideDeleteChecksModalAction} from "../../../../store/reducers/appReducer";
import {getCurrentDatasetAction, updateValidationAction} from "../../../../store/actions/datasetAction";


function DeleteChecksModal({check_delete,validChecks,setValidChecks}) {
    const dispatch = useDispatch()
    const {currentDataset,validations} = useSelector(store=>store.dataset)

    async function handleDeleteCheks(){
        let checked = [check_delete.name]

        let ds_valid = currentDataset.validations.map(val => val._id)
        let opt_val = validations.filter(el => el.optional).map(el => el._id)
        let old_opt_val = opt_val.filter(el => ds_valid.includes(el))

        let new_opt = old_opt_val.filter(el => !checked.includes(el))

        await dispatch(updateValidationAction(currentDataset._id,new_opt))
        await dispatch(getCurrentDatasetAction(currentDataset._id))
        let new_checked = validChecks
        new_checked = new_checked.map(check => {
            if(old_opt_val.includes(check.name)){
                check.value = false
            }
            return check
        })
        setValidChecks(new_checked)
        dispatch(hideDeleteChecksModalAction())

    }

    return (
        <div className="modal_checks">
            <div className="modal_checks_window">
                <div className="modal_checks_close">
                    <button className="gt_icon_btn" onClick={()=>dispatch(hideDeleteChecksModalAction())}>
                        <img src={IconClose} alt="close modal"/>
                    </button>
                </div>
                <img src={ImgDelete} className="img_delete_window" />
                <div className="modal_checks_text">
                    <p className="gt_h3">Удалить проверку?</p>
                    <p className="gt_pt">Вы действительно хотите удалить проверку<br/>
                        "Корректность категории разметки"?</p>
                </div>
                <div className="modal_checks_btn_group">
                <button type="button" className="gt_btn_secondary gt_btn_medium" onClick={()=>dispatch(hideDeleteChecksModalAction())}>
                        Отменить
                    </button>
                    <button type="button" className="gt_btn_primary gt_btn_medium" onClick={handleDeleteCheks}>
                        Удалить
                    </button>
                </div>

            </div>
        </div>
    )
}

export default DeleteChecksModal;
