import "./FilesAddComponent.scss"

import FileIcon from "../../../assets/icons/file_chose.svg"
import FolderIcon from "../../../assets/icons/folder_icn.svg"
import CloudIcon from "../../../assets/icons/cloud.svg"
import ParsingIcon from "../../../assets/icons/parsing.svg"
import {useEffect, useState} from "react";
import {getSanitizersAction, previewFileAction, uploadFileAction} from "../../../store/actions/FolderAction";
import {useDispatch, useSelector} from "react-redux";
import SelectGosteh from "../../SelectGosteh/SelectGosteh";
import IconDelete from "../../../assets/icons/icon_delete.svg";
import IconPlus from "../../../assets/icons/icon_plus.svg";
import SettingsIcon from "../../../assets/icons/m011.svg";
import UploadAnnotation from "../../MyRoom/Administrator/CreateDataset/UploadAnnotation";
import {useNavigate} from "react-router-dom";
import JpgIcon from "../../../assets/icons/jpg_icon.svg";
import DocIcon from "../../../assets/icons/doc.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import PngIcon from "../../../assets/icons/png_icon.svg";
import ZipIcon from "../../../assets/icons/zip.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import JsonIcon from "../../../assets/icons/icon_json.svg";
import CsvIcon from "../../../assets/icons/icon_csv.svg";
import TxtIcon from "../../../assets/icons/icon_txt.svg";
import UnknownIcon from "../../../assets/icons/unknown.svg";


function FilesAddComponent({sanitList,setSanitList,files,setFiles,uploadProcess,setUploadProcess}) {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {currentDataset,sanitizers} = useSelector(store=>store.dataset)
    const [options,setOptions] = useState([])

    const {importMode} = useSelector(store=>store.app)

    const [validFiles,setValidFiles] = useState(true)



    const accept_formats = currentDataset.info.data_type === "photo" && !importMode ? ["jpg","jpeg","png","bmp","zip","tar","gz"] :
        currentDataset.info.data_type === "time_series" && !importMode ? ["csv","zip","tar","gz"] :
            currentDataset.info.data_type === "text" && !importMode ? ["txt","docx"] : ["zip","tar","gz"]

    const def_opt = "Не выбрано"
    useEffect(()=>{

        dispatch(getSanitizersAction(currentDataset._id))

    },[currentDataset])

    useEffect(()=>{
        let opt_list = sanitizers.map(el => (el.name))
        setOptions(opt_list)
    },[sanitizers])


    function handleAddSanit(e){
        if(sanitList.length < sanitizers.length){
            setSanitList([...sanitList,{name:"",id:Date.now().toString()}])
        }
    }

    function handleSanitChange(option,index){
        let new_sanit = sanitList
        new_sanit[index].name = option
        setSanitList(new_sanit)
        let used_names = new_sanit.map(el => el.name)
        let new_opt = sanitizers.map(el => (el.name))
        new_opt = new_opt.filter( ( el ) => !used_names.includes( el ) );
        setOptions(new_opt)
    }

    function handleDeleteSanit(id){
        let name_opt = sanitList.filter(s=>s.id === id)[0].name
        setSanitList(sanitList.filter(s=>s.id !== id))
        let new_opt = options
        if (name_opt){
            new_opt.push(name_opt)
        }
        setOptions(new_opt)
    }

    function handlePreview(){
        if (currentDataset.info.data_type === "text"){
            dispatch(previewFileAction(Array.from(files),currentDataset._id))
        }
        else {
            let opts = sanitList.map(el => el.name)
            let sanit_list = sanitizers.filter(el => opts.includes(el.name)).map(el => el._id)
            dispatch(uploadFileAction(Array.from(files),currentDataset._id,sanit_list.toString()))
        }
        setUploadProcess(true)
    }


    return (
        <div className="add_files">
            <div style={{display: "flex",flexDirection:"column",gap:"24px"}}>
                <p className="gt_f3">Добавление файлов</p>
                <div className="change_upload">
                    <p className="gt_f6">Загрузка</p>
                    <div className="change_upload_block">
                        <button className="change_upload_item">
                            <img src={FileIcon} alt="file"/>
                            <p className="gt_pt">Файл</p>
                        </button>
                        <button className="change_upload_item disable_item">
                            <img src={FolderIcon} alt="folder"/>
                            <p className="gt_pt">Сетевая папка</p>
                        </button>
                        <button className="change_upload_item disable_item">
                            <img src={CloudIcon} alt="cloud"/>
                            <p className="gt_pt">Сетевая папка</p>
                        </button>
                        <button className="change_upload_item disable_item">
                            <img src={ParsingIcon} alt="parsing"/>
                            <p className="gt_pt">Парсинг сайт</p>
                        </button>
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <p className="gt_ps">Обработчики</p>
                    <div className="sanit_list">
                        {sanitList.map((sanit, i) => (
                            <div className="sanit_item" key={sanit.id}>
                                <div className="sanit_item_select">
                                    <SelectGosteh options={options} default_option={def_opt} value={sanit.name}
                                                  selectChange={handleSanitChange} index={i}/>
                                </div>
                                <div className="btn_set">
                                    <button type="button" className="delete_btn" disabled={true}>
                                        <img src={SettingsIcon} alt="settings" className="gt_icon_btn"/>
                                    </button>
                                    <button type="button" className="delete_btn"
                                            onClick={() => handleDeleteSanit(sanit.id)}>
                                        <img src={IconDelete} alt="delete" className="gt_icon_btn"/>
                                    </button>
                                </div>
                            </div>
                        ))}
                        <button type="button" className="add_satit" onClick={handleAddSanit}>
                            <img src={IconPlus} alt="icon plus"/>
                            <p className="gt_pt gt_gb">Добавить обработчик</p>
                        </button>
                    </div>
                </div>
                {uploadProcess ?
                    <div className="after_upload">
                        <p className="gt_ps">Прикрепленные файлы </p>
                        <div className="uploaded_files">
                            {files.map((file, index) => (
                                <div className="uploaded_item" key={file.name + index}>
                                    <div className="file_title">
                                        <img
                                            src={file.name.split(".").slice(-1)[0].toLowerCase() === 'jpg' || file.name.split(".").slice(-1)[0].toLowerCase() === "jpeg" ? JpgIcon :
                                                file.name.split(".").slice(-1)[0].toLowerCase() === 'doc' || file.name.split(".").slice(-1)[0].toLowerCase() === "docx" ? DocIcon :
                                                    file.name.split(".").slice(-1)[0].toLowerCase() === 'xls' || file.name.split(".").slice(-1)[0].toLowerCase() === "xlsx" ? XlsIcon :
                                                        file.name.split(".").slice(-1)[0].toLowerCase() === 'png' ? PngIcon :
                                                            file.name.split(".").slice(-1)[0].toLowerCase() === 'zip' ? ZipIcon :
                                                                file.name.split(".").slice(-1)[0].toLowerCase() === 'pdf' ? PdfIcon :
                                                                    file.name.split(".").slice(-1)[0].toLowerCase() === 'json' ? JsonIcon :
                                                                        file.name.split(".").slice(-1)[0].toLowerCase() === 'csv' ? CsvIcon :
                                                                            file.name.split(".").slice(-1)[0].toLowerCase() === 'txt' ? TxtIcon : UnknownIcon}
                                            alt="icon"/>
                                        <p className="gt_pt" style={{wordBreak: "break-all"}}>{file.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> :
                    <div>
                    <p className="gt_ps">Прикрепить файл </p>
                        <UploadAnnotation files={files} setFiles={setFiles} setValidFiles={setValidFiles} accept={false}
                                          accept_formats={accept_formats}/>
                    </div>
                }

            </div>
            <div className="btn_block">
                <button type="button" className="gt_btn_secondary" onClick={() => navigate("/my_room_admin")}>
                    {uploadProcess ? "Закрыть" : "Отменить"}
                </button>
                <button type="button" className="gt_btn_primary" disabled={!(files.length > 0) || uploadProcess}
                        onClick={handlePreview}>
                    Добавить
                </button>
            </div>
        </div>
    )
}

export default FilesAddComponent;
