import "./StatsProgressComponent.scss"


function StatsProgressComponent({end,progress}) {
    return (
        <div className="stats_progress">
            <div className="progress_bar">
                <div className="progress_with" style={{ width: progress + '%'}}/>
            </div>
            <div className="progress_text">
                <p className="gt_txs">0</p>
                <p className="gt_txs">{end}</p>
            </div>
        </div>
    )
}

export default StatsProgressComponent;
