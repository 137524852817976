import IconInfo from "../../../assets/icons/info.svg";
import "./HotKeyBtn.scss"
import {useDispatch} from "react-redux";
import {showHotkeyModalAction} from "../../../store/reducers/appReducer";

function HotKeyBtn() {

    const dispatch = useDispatch()

    return (
        <div className="help_btn">
            <button className="gt_btn_secondary btn_help" onClick={()=>dispatch(showHotkeyModalAction())}>
                <img src={IconInfo} alt="help"/>
                <p className="gt_pt">Горячие клавиши</p>
            </button>
        </div>
    )
}

export default HotKeyBtn;
