import "./SettingsLogComponent.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setDatasetLogsAction} from "../../../../store/reducers/datasetReducer";
import {getDatasetLogsAction} from "../../../../store/actions/FolderAction";
import IconLogError from "../../../../assets/icons/icon_log_error.svg";
import IconLogSuccess from "../../../../assets/icons/icon_log_success.svg";
import IconLogInfo from "../../../../assets/icons/icon_log_info.svg";
import IconSearch from "../../../../assets/icons/d08.svg";
import IconTime from "../../../../assets/icons/times.svg";
import WarningIcon from "../../../../assets/icons/b37.svg";


function SettingsLogComponent({search_display = true, preview ="", showPreview = ()=>{}}) {
    const dispatch = useDispatch()
    const {currentDataset,datasetLogs} = useSelector(store => store.dataset)

    useEffect(()=>{
        dispatch(setDatasetLogsAction([]))
        if(currentDataset.room_id){
            dispatch(getDatasetLogsAction(currentDataset._id,currentDataset.room_id))
        }
    },[currentDataset])

    const [types,setTypes] = useState([])
    const [search,setSearch] = useState("")

    function handleSelectType(type){
        if (types.includes(type)){
            setTypes(types.filter(t=>t !== type))
        }
        else {
            setTypes([...types,type])
        }
    }

    return (
        <div className="settings_logs">
            <div className="notification">
                {search_display ?
                    <div className="search">
                        <label htmlFor="search">
                            <img src={IconSearch} alt="search" className="gt_icon_btn"/>
                        </label>
                        <input placeholder="Поиск" id="search" className="gt_pt" value={search}
                               onChange={(e) => setSearch(e.target.value)}/>
                    </div>
                    : ""
                }


                <hr width="100%" color="#E4ECFD" size="1"/>


                <div className="notification_btn_group">
                    <div className="statuses">
                        <p className="gt_pt">Фильтры:</p>
                        <button type="button" className={`problem btn ${types.includes("error") ? "active" : ""}`}
                                onClick={() => handleSelectType("error")}>
                            <img src={IconLogError} alt="log error" className="gt_icon_btn"/>
                        </button>
                        <button type="button" className={`warning btn ${types.includes("warning") ? "active" : ""}`}
                                onClick={() => handleSelectType("warning")}>
                            <img src={WarningIcon} alt="log warning" className="gt_icon_btn"/>
                        </button>
                        <button type="button" className={`success btn ${types.includes("success") ? "active" : ""}`}
                                onClick={() => handleSelectType("success")}>
                            <img src={IconLogSuccess} alt="log success" className="gt_icon_btn"/>
                        </button>
                        <button type="button"
                                className={`information btn ${types.includes("information") ? "active" : ""}`}
                                onClick={() => handleSelectType("information")}>
                            <img src={IconLogInfo} alt="log info" className="gt_icon_btn"/>
                        </button>
                    </div>
                </div>

                <hr width="100%" color="#E4ECFD" size="1"/>

                <div className="log_list">
                    <p className="gt_f6">Информационные сообщения</p>
                    {datasetLogs.length === 0 && !preview ?
                        <div className="empty_logs">
                            <div className="empty_content">
                                <img src={IconTime} alt="time" style={{width:"120px"}}/>
                                <p className="gt_pt gt_ih" style={{textAlign:"center"}}>Информационных сообщений в данный момент нет</p>
                            </div>
                        </div> :
                        preview ?
                            <div className="log_preview_confirm">
                                <div className="log_preview_text">
                                    <img src={WarningIcon} alt="warning" className="gt_icon_btn"/>
                                    <p className="gt_pt">Загрузка остановлена. Подтвердите кодировку</p>
                                </div>
                                <button type="button" className="gt_btn_secondary" onClick={showPreview}>
                                    <p className="gt_pt">Подтвердить кодировку</p>
                                </button>
                            </div> : ""
                    }
                    {datasetLogs.filter(log => log.text.toLowerCase().includes(search.toLowerCase())).filter(log => types.length === 0 || types.includes(log.status)).map((log, i) => (
                        <div key={i} className="item">
                            <img src={log.status === "error" ? IconLogError :
                                log.status === "success" ? IconLogSuccess :
                                    IconLogInfo} alt="icon"/>

                            <p className="gt_pt gt_th time">{new Date(Number(log.time)).toLocaleTimeString("ru", {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}</p>
                            <p className="gt_pt text">{log.text}</p>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default SettingsLogComponent;

