import "./Login.scss";
import Gerb from "../../assets/icons/gerb.svg";
import GU_logo from "../../assets/icons/Госуслуги_лого.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAuthUrlAction, localLoginAction, refreshAccessTokenAction} from "../../store/actions/userAction";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AUTH_METHOD} from "../../utils/constants";
import IconHide from "../../assets/icons/closed.svg"
import IconEye from "../../assets/icons/eye.svg"
import IconUser from "../../assets/icons/User.svg"
import {setErrorLocalAuth} from "../../store/reducers/userReducer";



function Login () {
    const navigate = useNavigate();
    const local_auth = AUTH_METHOD === 'local' && window.location.pathname === "/auth/local"
    const dispatch = useDispatch()
    const {userInfo,errorLocalAuth} = useSelector(store=>store.user)
    const [login,setLogin] = useState()
    const [password,setPassword] = useState()
    const [hidePass,setHidePass] = useState(true)

    function handleLogin(){
        dispatch(getAuthUrlAction())
    }

    function handleLocalLogin(){
        if(login && password){
            dispatch(localLoginAction(login,password))
        }
    }

    useEffect(()=>{
        if (userInfo){
            dispatch(refreshAccessTokenAction())
            navigate("/my_room_admin");
        }
    },[])

    return (
        <div className="login">
            <div className={`login_cart ${local_auth ? "login_cart_local" : ""}`}>
                <div className="login_content">
                    <div className="login_title">
                        <img className="login_logo" src={Gerb} alt="Логотип"/>
                        <div className="gt_h5 gt_gdg">Государственная платформа искусственного интеллекта</div>
                    </div>
                    {local_auth ?
                        <div className="login_icon_block">
                            <img src={IconUser} alt="user_icon" className="login_user_icon"/>
                        </div> :
                        <div className="gt_pt login_text">
                            Для работы в Системе необходимо авторизоваться посредством ввода логина и пароля учетной записи ЕСИА.
                        </div>
                    }
                    {local_auth ?
                        <div className="login_local">
                            <input className={`login_local_input gt_pt ${errorLocalAuth ? "login_local_error" : ""}`}
                                   placeholder="Логин"
                                   type="text"
                                   value={login}
                                   onChange={(e) => setLogin(e.target.value)}
                                   onClick={() => dispatch(setErrorLocalAuth(false))}
                            />
                            <div className="pass_block">
                                <input className={`login_local_input gt_pt ${errorLocalAuth ? "login_local_error" : ""}`}
                                       placeholder="Пароль"
                                       type={hidePass ? "password" : "text"}
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       onClick={() => dispatch(setErrorLocalAuth(false))}

                                />
                                {errorLocalAuth && <p className="gt_ps gt_gr">Неправильный логин или пароль</p>}
                                <button className="pass_icon" type="button" onClick={() => setHidePass(!hidePass)}>
                                    <img alt="icon" src={hidePass ? IconHide : IconEye}/>
                                </button>
                            </div>
                            {/*
                            <button type="button">
                                <p className="gt_pt gt_gb">Восстановить</p>
                            </button>
                            */}
                            <button type="button" className="gt_btn_primary gt_pt login_btn" onClick={handleLocalLogin}>
                                Войти
                            </button>
                            <button type="button" onClick={handleLogin} className="gt_btn_gu">
                                <img className="login_btn_logo" src={GU_logo} alt="Госуслуги_лого"/>
                                <div className="gt_gu_btn">Войти через Госуслуги</div>
                            </button>
                        </div>
                        :
                        <button type="button" onClick={handleLogin} className="gt_btn_gu">
                            <img className="login_btn_logo" src={GU_logo} alt="Госуслуги_лого"/>
                            <div className="gt_gu_btn">Войти через Госуслуги</div>
                        </button>
                    }
                </div>

            </div>
        </div>
    )
}

export default Login;
