import "./CategoriesStatsComponent.scss"
import {useSelector} from "react-redux";
import StatsProgressComponent from "../StatsProgressComponent/StatsProgressComponent";

function CategoriesStatsComponent() {

    const {currentDataset,folderMode} = useSelector(store=>store.dataset)
    const {currentFolder} = useSelector(store=>store.folder)

    const currentItem = folderMode && currentFolder ? currentFolder : currentDataset

    let count_files  = 0
    let count_annotations  = 0

    if (currentItem){
        if (currentItem.files_per_cat){
            if (currentItem.files_per_cat.length > 0){
                currentItem.files_per_cat.forEach(file => {
                    count_files = count_files + file.count
                })
            }


            if (currentItem.annotations_per_cat.length > 0){
                currentItem.annotations_per_cat.forEach(file => {
                    count_annotations = count_annotations + file.count
                })
            }
        }
    }

    console.log(currentItem)
    return (
        <div className="categories_stats">

            {
                currentDataset.info.categories.filter(cat => !cat.is_default).map(cat => (
                    <div className="cat_item">
                        <hr width="100%" color="#E4ECFD" size="1"/>
                        <p className="gt_f6">Класс: {cat.name}</p>
                        <div className="stats_block">
                            <div className="stats_elem">
                                <div className="stats_row">
                                    <p className="gt_ps">Файлы</p>
                                    <p className="gt_ps">{count_files > 0 && currentItem.files_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentItem.files_per_cat.filter(file => file.id === cat.id)[0].count) / count_files * 100) + "%" : "0%"}</p>
                                </div>
                                <StatsProgressComponent end="100%"
                                                        progress={count_files > 0 && currentItem.files_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentItem.files_per_cat.filter(file => file.id === cat.id)[0].count) / count_files * 100) : 0}/>
                            </div>
                            <div className="stats_elem">
                                <div className="stats_row">
                                    <p className="gt_ps">Разметки</p>
                                    <p className="gt_ps">{count_annotations > 0 && currentItem.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentItem.annotations_per_cat.filter(file => file.id === cat.id)[0].count) / count_annotations * 100) + "%" : "0%"}</p>
                                </div>
                                <StatsProgressComponent end="100%"
                                                        progress={count_annotations > 0 && currentItem.annotations_per_cat.filter(file => file.id === cat.id).length > 0 ? Math.round(Number(currentItem.annotations_per_cat.filter(file => file.id === cat.id)[0].count) / count_annotations * 100) : 0}/>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CategoriesStatsComponent;
