import "./HotKeysComponent.scss"
import IconClose from "../../assets/icons/b01.svg";
import PlusIcon from "../../assets/icons/plus2.svg";
import {useDispatch} from "react-redux";
import {hideHotkeyModalAction} from "../../store/reducers/appReducer";

function RedactorBtns(){
    return (
        <div className="btn_blocks" style={{gap:"8px"}}>
            <button className="redactor_btn" disabled={true} >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M15.6663 3.62569C16.5006 2.79144 17.8531 2.79144 18.6874 3.62569L20.3743 5.3126C21.2086 6.14685 21.2086 7.49944 20.3743 8.33369L9.04388 19.6641C8.96666 19.7413 8.86931 19.7953 8.76291 19.8198L3.71363 20.9851C3.51789 21.0302 3.3127 20.9714 3.17065 20.8293C3.02861 20.6873 2.96976 20.4821 3.01493 20.2864L4.18015 15.2371C4.2047 15.1307 4.25866 15.0333 4.33587 14.9561L15.6663 3.62569ZM17.8635 4.44962C17.4843 4.07042 16.8694 4.07042 16.4902 4.44962L15.3055 5.63436L18.3656 8.6945L19.5504 7.50976C19.9296 7.13055 19.9296 6.51574 19.5504 6.13653L17.8635 4.44962ZM17.5417 9.51843L14.4816 6.4583L5.98374 14.9561L9.04388 18.0163L17.5417 9.51843ZM4.35992 19.6401L5.23365 15.8539L8.1461 18.7663L4.35992 19.6401Z"
                          fill="#0D4CD3"/>
                </svg>
            </button>

            <button className="redactor_btn" disabled={true}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.99782 5.33184C8.20421 3.9903 9.35852 3 10.7158 3H13.2842C14.6415 3 15.7958 3.99031 16.0022 5.33184L16.105 5.99996L19.25 5.99999C19.6642 5.99999 20 6.33578 20 6.74999C20 7.16421 19.6642 7.5 19.25 7.5H18.8833L18.0486 18.2326C17.9271 19.7945 16.6242 21 15.0576 21H8.94239C7.37576 21 6.07291 19.7945 5.95143 18.2326L5.11667 7.5H4.75C4.33579 7.5 4 7.16421 4 6.75C4 6.33578 4.33578 6 4.75 5.99999L7.89503 5.99996L7.99782 5.33184ZM14.5873 5.99996H9.41268L9.48037 5.55993C9.57419 4.95014 10.0989 4.5 10.7158 4.5H13.2842C13.9011 4.5 14.4258 4.95014 14.5196 5.55993L14.5873 5.99996ZM6.6212 7.5L7.44691 18.1163C7.50765 18.8972 8.15908 19.5 8.94239 19.5H15.0576C15.8409 19.5 16.4923 18.8972 16.5531 18.1163L17.3788 7.5L6.6212 7.5Z"
                          fill="#EE3F58"/>
                </svg>
            </button>
        </div>
    )
}

function HotKeysComponent() {

    const dispatch = useDispatch()

    return (
        <div className="modal_hotkey">
            <div className="modal_hotkey_window">
                <div className="modal_hotkey_close">
                    <p className="gt_h3">Горячие клавиши</p>
                    <button className="gt_icon_btn" onClick={()=>dispatch(hideHotkeyModalAction())}>
                        <img src={IconClose} alt="close modal"/>
                    </button>
                </div>
                <hr width="100%" color="#E4ECFD" size="1"/>
                <div className="modal_hotkey_btn_block">
                    <button className="hotkey_btn_service" disabled={true}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M18 7V5C18 3.89543 17.1046 3 16 3H8C6.89543 3 6 3.89543 6 5V7H5C3.34315 7 2 8.34315 2 10V16C2 17.6569 3.34315 19 5 19H6C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19H19C20.6569 19 22 17.6569 22 16V10C22 8.34315 20.6569 7 19 7H18ZM16 4.5H8C7.72386 4.5 7.5 4.72386 7.5 5V7H16.5V5C16.5 4.72386 16.2761 4.5 16 4.5ZM5 8.5H19C19.8284 8.5 20.5 9.17157 20.5 10V16C20.5 16.8284 19.8284 17.5 19 17.5H18V13C18 12.4477 17.5523 12 17 12H7C6.44771 12 6 12.4477 6 13V17.5H5C4.17157 17.5 3.5 16.8284 3.5 16V10C3.5 9.17157 4.17157 8.5 5 8.5ZM16 19.5H8C7.72386 19.5 7.5 19.2761 7.5 19V13.5H16.5V19C16.5 19.2761 16.2761 19.5 16 19.5Z"
                                  fill="#0D4CD3"/>
                        </svg>
                        <p className="gt_pt">Печать</p>
                    </button>
                    <div className="btn_blocks">
                        <button className="hotkey_btn_service" disabled={true}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8 4V7H4.91421C4.02331 7 3.57714 8.07714 4.20711 8.70711L11.2929 15.7929C11.6834 16.1834 12.3166 16.1834 12.7071 15.7929L19.7929 8.70711C20.4229 8.07714 19.9767 7 19.0858 7H16V4C16 3.44772 15.5523 3 15 3H9C8.44772 3 8 3.44772 8 4ZM9.5 8.5V4.5H14.5V8.5H17.8787L12 14.3787L6.12132 8.5H9.5Z"
                                      fill="#0D4CD3"/>
                                <path
                                    d="M2 19V16.75C2 16.3358 2.33579 16 2.75 16C3.16421 16 3.5 16.3358 3.5 16.75V19C3.5 19.2761 3.72386 19.5 4 19.5H20C20.2761 19.5 20.5 19.2761 20.5 19V16.75C20.5 16.3358 20.8358 16 21.25 16C21.6642 16 22 16.3358 22 16.75V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19Z"
                                    fill="#0D4CD3"/>
                            </svg>
                            <p className="gt_pt">Импорт</p>
                        </button>
                        <button className="hotkey_btn_service" disabled={true}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8 14.9998V11.9998H4.91421C4.02331 11.9998 3.57714 10.9227 4.20711 10.2927L11.2929 3.20696C11.6834 2.81643 12.3166 2.81643 12.7071 3.20696L19.7929 10.2927C20.4229 10.9227 19.9767 11.9998 19.0858 11.9998H16V14.9998C16 15.5521 15.5523 15.9998 15 15.9998H9C8.44772 15.9998 8 15.5521 8 14.9998ZM9.6 10.3998V14.3998H14.4V10.3998H17.6373L12 4.76259L6.36274 10.3998H9.6Z"
                                      fill="#0D4CD3"/>
                                <path
                                    d="M2 18.9999V16.7499C2 16.3356 2.33579 15.9999 2.75 15.9999C3.16421 15.9999 3.5 16.3356 3.5 16.7499V18.9999C3.5 19.276 3.72386 19.4999 4 19.4999H20C20.2761 19.4999 20.5 19.276 20.5 18.9999V16.7499C20.5 16.3356 20.8358 15.9999 21.25 15.9999C21.6642 15.9999 22 16.3356 22 16.7499V18.9999C22 20.1044 21.1046 20.9999 20 20.9999H4C2.89543 20.9999 2 20.1044 2 18.9999Z"
                                    fill="#0D4CD3"/>
                            </svg>
                            <p className="gt_pt">Экспорт</p>
                        </button>
                    </div>
                </div>
                <hr width="100%" color="#E4ECFD" size="1"/>
                <div className="hotkeys_block">
                    <p className="gt_f6">Графическая разметка</p>
                </div>
                <div className="hotkeys_info">
                    <div className="hotkeys_info_item">
                        <p className="gt_pt">Удалить разметку</p>
                        <div className="key_block_multy">
                            <div className="key_block">
                                <p className="gt_pt">del/backspace</p>
                            </div>
                        </div>
                        <RedactorBtns/>
                    </div>

                    <div className="hotkeys_info_item">
                        <p className="gt_pt">Отменить действие</p>
                        <div className="key_block_multy">
                            <div className="key_block">
                                <p className="gt_pt">Ctrl</p>
                            </div>
                            <img src={PlusIcon} alt="plus"/>
                            <div className="key_block">
                                <p className="gt_pt">Z</p>
                            </div>
                        </div>
                        <RedactorBtns/>
                    </div>

                    <div className="hotkeys_info_item">
                        <p className="gt_pt">Огрубить в режиме «Волшебная палочка»</p>
                        <div className="key_block_multy">
                            <div className="key_block">
                                <p className="gt_pt">Enter</p>
                            </div>
                            <img src={PlusIcon} alt="plus"/>
                            <div className="key_block">
                                <p className="gt_pt">Rounding</p>
                            </div>
                        </div>
                        <RedactorBtns/>
                    </div>

                    <div className="hotkeys_info_item">
                        <p className="gt_pt">Обьединить контуры с новой разметкой ВП</p>
                        <div className="key_block_multy">
                            <div className="key_block">
                                <p className="gt_pt">Shift</p>
                            </div>
                            <img src={PlusIcon} alt="plus"/>
                            <div className="key_block">
                                <p className="gt_pt">Click</p>
                            </div>
                        </div>
                        <RedactorBtns/>
                    </div>

                    <div className="hotkeys_info_item">
                        <p className="gt_pt">Удалить точку с ребра</p>
                        <div className="key_block_multy">
                            <div className="key_block">
                                <p className="gt_pt">Alt</p>
                            </div>
                            <img src={PlusIcon} alt="plus"/>
                            <div className="key_block">
                                <p className="gt_pt">Click</p>
                            </div>
                        </div>
                        <RedactorBtns/>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default HotKeysComponent;
