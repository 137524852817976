import "./PreviewModalComponent.scss"
import IconClose from "../../../assets/icons/b01.svg";
import SelectGosteh from "../../SelectGosteh/SelectGosteh";
import {useDispatch, useSelector} from "react-redux";
import {previewFileAction, uploadFileAction} from "../../../store/actions/FolderAction";
import {changeProcessingProgressAction, changeUploadProgressAction} from "../../../store/reducers/folderReducer";


function PreviewModalComponent({hidePreview,sanitList,files}) {
    const options = ["utf-8","cp1251","cp866"]
    const dispatch = useDispatch()
    const {currentDataset,sanitizers} = useSelector(store=>store.dataset)

    const {preview} = useSelector(store=>store.folder)

    function uploadFiles(){
        let opts = sanitList.map(el => el.name)
        let sanit_list = sanitizers.filter(el => opts.includes(el.name)).map(el => el._id)
        dispatch(uploadFileAction(Array.from(files),currentDataset._id,sanit_list.toString()))

        dispatch(changeProcessingProgressAction(0))
        dispatch(changeUploadProgressAction(0))

        hidePreview()
    }

    function handleSelect(opt){
        dispatch(previewFileAction(Array.from(files),currentDataset._id,opt))
    }
    return (
        <div className="modal_preview">
            <div className="modal_window">
                <div className="preview_header">
                    <p className="gt_f3">Превью файла</p>
                    <button type="button" className="gt_icon_btn" onClick={hidePreview}>
                        <img src={IconClose} alt="close modal"/>
                    </button>
                </div>
                <hr width="100%" color="#E4ECFD" size="1"/>
                <div className="preview_encoding">
                    <p className="gt_ps">Кодировка</p>
                    <SelectGosteh options={options} value={preview.encoding}  selectChange={handleSelect}/>
                </div>
                <div className="preview_content">
                    <p className="gt_pt">
                        {preview.data}
                    </p>
                </div>
                <div className="btn_block">
                    <button type="button" className="gt_btn_secondary" onClick={hidePreview}>
                        Прервать загрузку
                    </button>
                    <button type="button" className="gt_btn_primary" onClick={uploadFiles}>
                        Подтвердить кодировку
                    </button>
                </div>
            </div>
        </div>
    )

}

export default PreviewModalComponent;
