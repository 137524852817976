import "./Menu.scss";
import Arrow from "../../assets/icons/b14.svg"
import Home from "../../assets/icons/d01.svg";
import Database from "../../assets/icons/Database.svg";
import {useState} from "react";

function Menu () {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div className="menu_close">
                <button className="menu_close_arrow_btn" onClick={()=>setOpen(true)}>
                    <img src={Arrow} alt="arrow"/>
                </button>
                <button className="menu_close_item">
                    <img src={Home} alt="home"/>
                </button>
                <button className="menu_close_item">
                    <img src={Database} alt="arrow"/>
                </button>
            </div>
            <div className="menu_open" style={{left: open ? "0" : "-300px"}}>
                <button className="menu_open_arrow_btn" onClick={() => setOpen(false)}>
                    <img src={Arrow} alt="arrow" className="menu_open_arrow_back"/>

                </button>
                <button className="menu_open_item">
                    <img src={Home} alt="home"/>
                    <p className="gt_pt">Моя комната</p>
                </button>
                <button className="menu_open_item">
                    <img src={Database} alt="arrow"/>
                    <p className="gt_pt">Опубликованные датасеты</p>
                </button>
            </div>
        </div>

    )
}

export default Menu;
