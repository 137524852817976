import "./DatasetChecks.scss"
import {useEffect, useState} from "react";
import CheckboxGosteh from "../../../CheckboxGosteh/CheckboxGosteh";
import IconError from "../../../../assets/icons/icon_log_error.svg";
import IconSuccess from "../../../../assets/icons/icon_log_success.svg";

import {useDispatch, useSelector} from "react-redux";
import IconDelete from "../../../../assets/icons/d04.svg";
import IconDeleteRed from "../../../../assets/icons/d04r.svg";
import {showDeleteChecksModalAction} from "../../../../store/reducers/appReducer";
import DeleteChecksModal from "../DeleteChecksModal/DeleteChecksModal";

function DatasetChecks({setOpt,validChecks}){
    const {currentDataset,validations} = useSelector(store=>store.dataset)
    const [checks,setChecks] = useState([])
    const {showDeleteChecksModal} = useSelector(store=>store.app)

    const [checkDelete,setCheckDelete] = useState("")

    const dispatch = useDispatch()
    useEffect(()=>{
        if (validations.length > 0){
            let list = currentDataset.validations.map(ds_val => {
                let opt = validations.filter(val => val._id === ds_val._id)
                return  {
                    name:ds_val._id,
                    label:opt.length > 0 ? opt[0].name : "",
                    value:false,
                    done: ds_val.done
                    }
                }
            )
            setChecks(list)
        }


    },[currentDataset,validations])


    function changeCheck(name,value){
        let new_checks = checks
        let index = new_checks.findIndex(p => p.name === name);
        new_checks[index].value = value
        setChecks(new_checks)
        setOpt(new_checks)
    }

    function handleDeleteChecks(check){
        dispatch(showDeleteChecksModalAction())
        setCheckDelete(check)
    }

    return(
        <div className="ds_checks_block">
            {checks.map(check => (
                <div className="ds_checks_conteiner" key={check.name}>
                    <div key={check.name} className="ds_checks_item">
                        {check.done === null ? <div style={{width:"16px"}}></div> :
                            <img src={check.done ? IconSuccess : IconError} alt="status" style={{width:"16px"}}/>
                        }

                        <CheckboxGosteh
                            key={check.name}
                            label={check.label}
                            check_value={check.value}
                            onChange={changeCheck}
                            name={check.name}
                        />
                    </div>
                        {
                            currentDataset.roles.includes("admin") && validations.filter(val => val._id === check.name).length > 0
                            && validations.filter(val => val._id === check.name)[0].optional ?
                            <div>
                                <button type="button" className="del_checks_btn" onClick={()=>handleDeleteChecks(check)}>
                                    <img src={IconDelete} alt="icon delete"
                                         onMouseOver={e => (e.currentTarget.src = IconDeleteRed)}
                                         onMouseOut={e => (e.currentTarget.src = IconDelete)}
                                    />
                                </button>
                            </div> : ""
                        }
                </div>
            ))}
            {showDeleteChecksModal && <DeleteChecksModal check_delete={checkDelete} validChecks={validChecks} setValidChecks={setOpt} />}

        </div>
    )
}






export default DatasetChecks;
