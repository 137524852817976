import "../../SettingsDatasetBlock/SettingsDatasetBlock.scss"
import "../../SettingsDatasetBlock/SettingsRequirementsComponent/SettingsRequirementsComponent.scss"
import "./ViewFolderComponent.scss"
import LogSuccessIcon from "../../../../assets/icons/icon_log_success.svg";
import LogErrorIcon from "../../../../assets/icons/icon_log_error.svg";

import CategoriesStatsComponent from "../../SettingsDatasetBlock/CategoriesStatsComponent/CategoriesStatsComponent";
import {useSelector} from "react-redux";
import SettingsBigIcon from "../../../../assets/icons/big_settings.svg";



function ViewFolderComponent() {
    const {currentFolder} = useSelector(store=>store.folder)

    return (
        <div className="settings_block">
            <div className="settings_header">
                <p className="gt_h4">Просмотр</p>
            </div>
            {currentFolder ?
                <>
                    <div className="settings_requirements">
                        <div className="settings_processing">
                            <p className="gt_pt">Обработано:</p>
                            <div className="stats">
                                <img
                                    src={(currentFolder.annotated === currentFolder.uploaded) && (currentFolder.uploaded !== 0) ? LogSuccessIcon : LogErrorIcon}
                                    alt="error"/>
                                <p className="gt_pt">{currentFolder.annotated} / {currentFolder.uploaded}</p>
                            </div>
                        </div>


                        <div className="settings_checks">
                            <div className="settings_checks_header">
                                <p className="gt_pt">Плановое количество файлов:</p>
                                <div className="settings_checks_stats">
                                    <div className="stats">
                                        <img
                                            src={currentFolder.uploaded >= currentFolder.info.counts ? LogSuccessIcon : LogErrorIcon}
                                            alt="error"/>
                                        <p className="gt_pt">{currentFolder.uploaded} / ?</p>
                                    </div>

                                </div>
                            </div>

                            <div className="files_info">
                                <CategoriesStatsComponent/>
                            </div>

                        </div>
                    </div>
                </> :
                <div className="folder_view">
                    <div className="empty_folders">
                        <div className="empty_folders_content">
                            <img src={SettingsBigIcon} alt="folder"/>
                            <p className="gt_pt gt_th">Настройки будут доступны после <br/> добавления набора данных</p>
                        </div>
                    </div>
                </div>


            }

        </div>

    )
}

export default ViewFolderComponent;
