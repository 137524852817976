import "./UploadPageComponent.scss"
import FilesAddComponent from "./FilesAddComponent/FilesAddComponent";
import UploadProgressComponent from "./UploadProgressComponent/UploadProgressComponent";
import {useNavigate} from "react-router-dom";
import IconBack from "../../assets/icons/icon-arrow-left.svg";
import {useState} from "react";



function UploadPageComponent(){
    const navigate = useNavigate();

    const [sanitList,setSanitList] = useState([])
    const [files,setFiles] = useState([])
    const [uploadProcess,setUploadProcess] = useState(false)

    function handleBack(){
        navigate("/my_room_admin");
    }

    return (
        <div className="upload_page">
            <div className="back_upload">
                <button type="button" onClick={handleBack} className="back_btn">
                    <img src={IconBack} alt="Logo"/>
                    <p className="gt_h4 gt_gb">Назад</p>
                </button>
            </div>
            <div className="content_block">
                <FilesAddComponent sanitList={sanitList} setSanitList={setSanitList} files={files} setFiles={setFiles}
                                   uploadProcess={uploadProcess} setUploadProcess={setUploadProcess}/>
                <UploadProgressComponent sanitList={sanitList} files={files}/>
            </div>
        </div>
    )
}


export default UploadPageComponent;
