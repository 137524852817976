import "./SettingsRequirementsComponent.scss"
import LogSuccessIcon from "../../../../assets/icons/icon_log_success.svg";
import LogErrorIcon from "../../../../assets/icons/icon_log_error.svg";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import DatasetChecks
    from "../DatasetChecks/DatasetChecks";
import {
    getCurrentDatasetAction,
    getValidationsAction
} from "../../../../store/actions/datasetAction";
import {
    getActiveValidateEventsAction,
    getEventsAction,
    startValidationAction
} from "../../../../store/actions/FolderAction";
import {showChecksModalAction} from "../../../../store/reducers/appReducer";
import IconPlus from "../../../../assets/icons/icon_plus.svg";

function SettingsRequirementsComponent() {

    const {currentDataset,validationProgress,validationProgressShow,activateValidateInterval,validationEvent} = useSelector(store=>store.dataset)

    const [showCheks,setShowCheks] = useState(true);
    //const [showFilesInfo,setShowFilesInfo] = useState(false);

    const [validChecks,setValidChecks] = useState([])

    const dispatch = useDispatch()



    useEffect(()=>{
        dispatch(getValidationsAction(currentDataset._id))
    },[currentDataset])

    useEffect(()=>{
        if(!activateValidateInterval) return;

        const interval = setInterval(() => {
            if (!validationEvent) return;
            dispatch(getEventsAction(validationEvent,currentDataset.room_id))
            dispatch(getCurrentDatasetAction(currentDataset._id))
        }, 2000);

        return () => clearInterval(interval);
    },[activateValidateInterval,validationEvent])

    useEffect(()=>{
        if(currentDataset.room_id){
            dispatch(getActiveValidateEventsAction(currentDataset._id,currentDataset.room_id))
        }
    },[currentDataset])


    function handleStartValidation(){
        if (validChecks.length > 0){
            let validation_list = validChecks.filter(val => val.value).map(val=>val.name)
            dispatch(startValidationAction(currentDataset._id,currentDataset.room_id,validation_list))
        }

    }

    return (
        <div className="settings_requirements">
            {
                /*
                <div className="settings_processing">
                <p className="gt_pt">Обработано:</p>
                <div className="stats">
                    <img
                        src={(currentDataset.annotated === currentDataset.uploaded) && (currentDataset.uploaded !== 0) ? LogSuccessIcon : LogErrorIcon}
                        alt="error"/>
                    <p className="gt_pt">{currentDataset.annotated} / {currentDataset.uploaded}</p>
                </div>
            </div>
                 */
            }


            <div className="settings_checks">
                <div className="settings_checks_header">
                    <p className="gt_pt">Проверки:</p>
                    <div className="settings_checks_stats">
                        <div className="stats">
                            <img
                                src={currentDataset.validations.filter(val => val.done).length === currentDataset.validations.length ? LogSuccessIcon : LogErrorIcon}
                                alt="error"/>
                            <p className="gt_pt">{currentDataset.validations.filter(val => val.done).length} / {currentDataset.hasOwnProperty("validations") ? currentDataset.validations.length : "?"}</p>
                        </div>
                        {
                            /*
                            !showCheks ?
                                <button type="button" className="gt_icon_btn"
                                        onClick={()=> {
                                            setShowCheks(true);
                                            setShowFilesInfo(false);
                                        }}>
                                    <img src={ArrowDownIcon} alt="arrowDown"/>
                                </button>
                                :
                                <button type="button" className="gt_icon_btn" onClick={()=>setShowCheks(false)}>
                                    <img src={ArrowUpIcon} alt="arrowUp"/>
                                </button>

                             */
                        }
                    </div>
                </div>
                <hr width="100%" color="#E4ECFD" size="1"/>
                {showCheks &&
                    <div className="checks_show_block">
                        {validationProgressShow &&
                            <div className="validate_progress">
                                <div className="progress_block__progress-bar">
                                    <div
                                        className="progress_block__upload-bar"
                                        style={{width: validationProgress + '%'}}
                                    />
                                </div>
                            </div>
                        }

                        <DatasetChecks setOpt={setValidChecks} validChecks={validChecks}/>
                        {currentDataset.roles.includes("admin") &&
                            <div className="cheks_contol_btn">
                                <button type="button" className="add_cheks"
                                        onClick={() => dispatch(showChecksModalAction())}>
                                    <img src={IconPlus} alt="icon plus"/>
                                    <p className="gt_pt gt_gb">Добавить проверку</p>
                                </button>
                            </div>

                        }
                        <hr width="100%" color="#E4ECFD" size="1"/>
                    </div>
                }
                <div className="checks_start_btn" style={showCheks ? {justifyContent:"end"} : {}}>
                    <button type="button" className="gt_btn_secondary"
                            disabled={!currentDataset.room_id || !(currentDataset.uploaded > 0)}
                            onClick={handleStartValidation}>
                        <p className="gt_pt">Запустить проверку</p>
                    </button>
                </div>
            </div>

            {
                /*
                    <div className="settings_checks">
                <div className="settings_checks_header">
                    <p className="gt_pt">Плановое количество файлов:</p>
                    <div className="settings_checks_stats">
                        <div className="stats">
                            <img
                                src={currentDataset.uploaded >= currentDataset.info.counts ? LogSuccessIcon : LogErrorIcon}
                                alt="error"/>
                            <p className="gt_pt">{currentDataset.uploaded} / {currentDataset.info.counts}</p>
                        </div>
                        {
                            !showFilesInfo ?
                                <button type="button" className="gt_icon_btn"
                                        onClick={() => {
                                            setShowFilesInfo(true);
                                            setShowCheks(false);
                                        }}>
                                    <img src={ArrowDownIcon} alt="arrowDown"/>
                                </button>
                                :
                                <button type="button" className="gt_icon_btn" onClick={()=>setShowFilesInfo(false)}>
                                    <img src={ArrowUpIcon} alt="arrowUp"/>
                                </button>
                        }

                    </div>
                </div>
                {showFilesInfo ?
                    <div className="files_info">
                        <CategoriesStatsComponent />
                    </div>
                    : ""
                }
            </div>
                 */
            }

        </div>
    )
}

export default SettingsRequirementsComponent;
